<template>
  <div class="hello">
    <div class="s-nav">
      <ul>
        <div class="logo"></div>
        <li>
          <a href="#index1" @click="activeNav = 'home'" :class="{ active: activeNav === 'home' }">喵喵乐趣</a>
        </li>
        <li>
          <a href="#index2" @click="activeNav = 'about'" :class="{ active: activeNav === 'about' }">使用指南</a>
        </li>
        <li>
          <a href="#index3" @click="activeNav = 'contact'" :class="{ active: activeNav === 'contact' }">IOS下载</a>
        </li>
      </ul>
    </div>
    <div class="page_wrapper">
      <div class="ul">
        <div class="li index1" id="index1">
          <div class="flex_main">
            <div class="flex_content">
              <div class="mains">
                <img src="./../assets/logo.png" alt="">
              </div>
              <div class="title" >喵喵乐趣APP</div>
              <div class="text">超解压消除游戏，随时娱乐一下</div>
            </div>
          </div>
         
        </div>
        <div class="li index2" id="index2">
          <div class="main2">
            <div class="title">动动手指，获得持续快乐和放松</div>
            <div class="text">没有失败的压迫，只有解压的爽快</div>
            <div class="index2_flex">
              <div class="left">
                <img src="./../assets/img2.jpg" alt="">
                <div class="title">超级消除</div>
                <div class="text">每局不超过1分钟，让您感受疯狂消除的快感</div>
              </div>
              <div class="right">
                <img src="./../assets/img1.png" alt="">
                <div class="title">闯关奖励</div>
                <div class="text">关关领宝箱，随机爆出大量金币奖励和道具  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="li index3" id="index3">
          <div class="title">敬请期待</div>
          <div class="text">苹果App Store上架中...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      activeNav: 'home' // 默认激活的导航项
    }
  }
 }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 html {
    scroll-behavior: smooth; /* 开启平滑滚动 */
  }
.logo {
  width: 30px;
  height: 30px;
  background: url(./../../src/assets/logo.png) left top no-repeat;
  background-size: cover;
  cursor: pointer;
  position: fixed;
    left: 10px;
    margin-left: 0;
    top: 6px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.s-nav {
    position: fixed;
    top: 0;
    z-index: 210;
    width: 100%;
    left: 0;
}
.s-nav ul {
  background-color: rgba(33,44,55,.7);
  color: #eee;
  text-align: center;
    padding: 0 15px;
    margin: 0;
}
.s-nav ul li {
  display: inline-block;
  zoom: 1;
}
.s-nav ul li a {
  display: block;
    padding: 13px 10px;
    color: #fff;
    transition: background-color .3s linear;
    font-size: 15px;
    font-weight:300;
    text-decoration:none;
} 

.s-nav ul li a.active { 
  background-color:#9b59b6;
}


.s-nav ul li a:hover {
 background-color:#9b59b6;
}
.s-nav ul li a:selected {
 background-color:#9b59b6;
}
/* 页面 */
.page_wrapper {
  min-height: 100%;

}
.page_wrapper .ul {
  width: 100%;
}
.page_wrapper .ul .li {
  transition: all .8s cubic-bezier(.26,.86,.44,.985);
  position: relative;
    outline: none;
}
.page_wrapper .ul .index1 {
  background-color:rgb(116, 207, 226);
 
}
.flex_main {
  display: -webkit-flex; 
  display: flex;
  min-height: 100vh;
  box-sizing: border-box;
  /* -webkit-align-items: center; */
  align-items: center;
  text-align: center;
  min-height:100vh;
  box-sizing:border-box;
  -webkit-align-items:center;
  width: 100%;
}
.flex_content {
  margin: 0 auto;
}
.flex_content .title{
  margin-top: 20px;
  color: rgb(95, 53, 187);
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 15px;
}
.flex_content .text{
  color: rgb(31, 33, 35);
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.page_wrapper .ul .index1 img {
  width: 128px;
  display: inline-block;
}
.mains {
  width: 100%;
}
/* 第二部分 */
.index2 {
  padding:  90px 0;
  background: rgb(236, 240, 241);
}
.index2 .main2 {

}
.index2 .main2 .title {
  font-size: 36px;
  line-height: 1.2;
  color: rgb(154, 124, 218);
  margin-bottom: 15px;

}
.index2 .main2 .text {
  color: rgb(31, 33, 35);
  font-size: 22px;
  line-height: 1.2;
}
.index2 .index2_flex {
  display: flex;
  justify-content: center;
  padding-top: 75px;
}
.index2_flex .left {
  margin: 0 10px;
}
.index2_flex .left img {
  width: 188px;
  display: block;
}
.index2_flex .right {
  margin: 0 10px;
}
.index2 .index2_flex img {
  width: 188px;
  display: block;
}
.index2 .index2_flex .title {
  font-weight: 700;
  font-size: 36px;
  color: rgb(80, 85, 92);
  width: 188px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.index2 .index2_flex .text {
  width: 188px;
  text-align: center;

  font-size: 18px;
  color: rgb(80, 85, 92);
}
/* 第三部分 */
.index3 {
  width: 100%;
  background: url(./../../src/assets/bgk.jpg) left top no-repeat;
  background-size: cover;
    background-color: transparent;
    background-position: 50% 75%;
    padding: 130px 0 190px 0;
}
.index3 .title {
  font-size: 36px;
  line-height: 1.2;
  color: #fff;

  margin-bottom: 15px;
}
.index3 .text {
  color: #7f8690;
  font-size: 22px;
}
</style>
