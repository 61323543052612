import Vue from 'vue'
import App from './App.vue'

// 添加全局样式
Vue.prototype.$_setBodyStyle = () => {
  const bodyStyle = document.body.style
  bodyStyle.margin = '0'
  bodyStyle.padding = '0'
  bodyStyle.fontFamily = "'Avenir', Helvetica, Arial, sans-serif"
  bodyStyle.backgroundColor = '#f8f8f8'
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
